<template>
	<div>
		<div ref="project" class="sub-tit"><p>참여중인 프로젝트</p></div>

		<!-- 관련내용없는경우 -->
		<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj none">
			<p>프로젝트가 없습니다!</p>
		</div>

		<!-- 참여중인 프로젝트 리스트 -->
		<div v-for="project in projectList" :key="project.projectSeq" class="view int_prj">
			<div class="req">
				<div class="top">
					<div class="proc pf">참 여 중</div>
				</div>
				<div class="itv-outline int_prj">
					<div class="regi">
						<div v-if="project.projectDivCd == '11' || project.projectDivCd == '12'" class="tit int_prj">프로젝트명</div>
						<div v-else class="tit_sco">공급기업 지정</div>
						<div class="name" style="width: 750px;" @click="clickProjectInfo(project.projectSeq)">{{project.projectNm}}</div><div class="date">등록일자 {{project.regYyyymmdd}}</div>
						<div class="sub-new">
							<div class="label ivl04">고객사</div>
							<div class="list" style="display: inline-flex; align-items: center;">
								<div class="add">{{project.clientNm}}</div>
								<div class="loca"><img src="/images/location.png" alt="위치"/>{{project.projectLocCont}}</div>
							</div>
						</div>
					</div>
					<div class="int_box">
						<div class="list-view int_prj">
							<div class="label ivl04">수행사</div><div class="list">{{project.corpNm}}</div>
						</div>
						<div class="list-view int_prj">
							<div class="label ivl04">담당자</div><div class="list">{{project.corpMberNm}}</div>
						</div>
						<div class="list-view int_prj">
							<div class="label ivl05">개발기간</div><div class="list">{{project.projectStartEndYyyymmdd}}</div>
							<div class="label period">참여기간</div><div class="list">{{project.projectRecrStartEndYyyymmdd}}</div>
						</div>
						<div class="list-view int_prj">
							<div class="label ivl03">개요</div><div class="list text">{{project.projectDtlDesc}}</div>
						</div>
						<div class="list-view int_prj">
							<div class="label ivl05">개발기술</div><div class="list">{{project.devTechCont}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>

	</div>

</template>

<script>

/**
 * 기술인재의 참여중인 프로젝트 목록
 */
import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	props: {
        tecMberSeq : String  
    },

	data() {
		return {
			input: {
				pageIndex : '1',
				tecMberSeq : 0,
				projectRectStatus : '1'		/* 프로젝트 참여상태 1:참여중, 2:참여예정, 9:참여완료 */
			}, // 검색조건	
			
			data : {},

			projectList : [],
			pageInfo : {},
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');

		if(this.$route.query.tecMberSeq != undefined && this.$route.query.tecMberSeq != '') {
			this.input.tecMberSeq = this.$route.query.tecMberSeq;
		} else {
			this.input.tecMberSeq = this.tecMberSeq;
		}
	},
	mounted() {
		// console.log('mounted');
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		
		getList() {
			
			this.$.httpPost('/api/prj/adm/getTecMberProjectList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList 	= res.data.list;
					this.pageInfo 		= res.data.pageInfo;

					this.$emit('searchEnd', res.data);
				}).catch(this.$.httpErrorCommon);
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$emit('move', {name: 'PRJ901M02', query : {tecMberSeq : this.input.tecMberSeq, projectSeq : projectSeq}, params });
			//this.$router.push({name: 'PRJ901M02', query : {tecMberSeq : this.input.tecMberSeq, projectSeq : projectSeq}, params });
		},
	},
};
</script>
